<template>
  <div
    class="container justify-content-center aligh-items-center export"
    style="min-height: 50vh"
  >
    <div class="row mt-5">
      <div class="col">
        <div class="card p-4">
          <div class="card-">
            <div v-if="error" class="alert alert-danger">
              Une erreur s'est produite lors de l'opération.
            </div>
            <h2 class="mb-2">
              {{ !exporting ? "Exportation terminée" : "Exportation en cours ....." }}
            </h2>
            <span v-if="exporting" for=""
              >Cette opération peut prendre plusieurs minutes.</span
            >
            <p-progress-bar
              :value="progression"
              :mode="exporting ? 'indeterminate' : 'determinate'"
            />
            <!-- <p-progress-bar :value="progression" /> -->
          </div>
          <div>Fichier: {{ query.metaTitle }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exporting: true,
      error: false,
      fetching: false,
      query: {},
      page: 0,
      pageCount: 1,
      pagination: {
        last: false,
        number: -1,
        numberOfElements: 200,
        size: 30000,
      },
      demandeurs: [],
    };
  },
  created() {
    this.query = this.$route.query;
    this.fetchNextPage();
    window.onbeforeunload = function () {
      return "Un ActivitePlanifieration d'exportation en excel est actuellement en cours. Voulez-vous l'arrêter?";
    };
  },
  watch: {},
  computed: {
    progression() {
      return this.pagination.number == -1
        ? 0
        : Math.floor(((this.pagination.number + 1) / this.pagination.totalPages) * 100);
    },
    completed() {
      return this.progression == 100;
    },
  },
  methods: {
    fetchNextPage() {
      this.fetching = true;
      this.exporting = true;
      let {
        queryParams,
        apiPath,
        fetcherMethod,
        metaSubtitle,
        metaTitle,
      } = this.$route.query;

      if (apiPath == null) apiPath = fetcherMethod;

      // queryParams = queryParams.split('&').map(el => el.startsWith('size=')? `size=${this.pagination.size}` :  el.startsWith('page=')? `page=${this.pagination.number + 1}` : el).join('&')
      queryParams = queryParams
        .split("&")
        .map((el) =>
          el.startsWith("size=")
            ? `size=${this.pagination.size}`
            : el.startsWith("page=")
            ? `page=${this.pagination.number + 1}`
            : el
        )
        .join("&");
      if (queryParams.indexOf("page=") == -1) {
        queryParams += `&page=${this.pagination.number + 1}`;
      }
      const exportType = apiPath.indexOf("ToPdf") != -1 ? "exportToPdf" : "exportToExcel";
      this.$store
        .dispatch(`exporter/${exportType}`, {
          apiPath,
          queryParams: queryParams,
          meta: {
            title: metaTitle,
            subtitle: metaSubtitle,
          },
        })
        .then((data) => {
          this.exporting = false;
        })
        .catch((err) => {
          this.error = true;
          this.exporting = false;
        });
    },
  },
};
</script>

<style></style>
